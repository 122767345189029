import { helmetJsonLdProp } from "react-schemaorg";
import { Helmet } from "react-helmet-async";

export default function JSONLDHelmet({
  // canonicalUrl,
  jobDetails: {
    title = "",
    description = "",
    postedDate = "",
    closeDate = "",
    employmentType = "",
  },
}) {
  return (
    <>
      <Helmet
        script={[
          helmetJsonLdProp({
            "@context": "https://schema.org/",
            "@type": "JobPosting",
            title,
            description,
            datePosted: postedDate,
            validThrough: closeDate,
            applicantLocationRequirements: {
              "@type": "Country",
              name: "USA",
            },
            jobLocationType: "REMOTE",
            employmentType,
            hiringOrganization: {
              "@type": "Organization",
              name: "Flow Global Technologies Inc.",
              sameAs: "https://www.flowai.tech/",
              logo: "https://www.example.com/images/logo.png",
            },
          }),
        ]}
      >
        <meta charSet="utf-8" />
        <title>Flow Careers | {title}</title>
      </Helmet>
    </>
  );
}
