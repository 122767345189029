import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './HRLogin.css';

const HRLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API;

  console.log('API Base URL:', API_BASE_URL);
  console.log('email')
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await axios.post(`${API_BASE_URL}api/hr/login/`, {
        email,
        password
      });
      localStorage.setItem('accessToken', response.data.access);
      localStorage.setItem('refreshToken', response.data.refresh);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      navigate('/internal-hr-joblist');
    } catch (error) {
      console.error('Login error:', error.response ? error.response.data : error.message);
      setError('Invalid credentials');
    }
  };

  return (
    <div className="hr-login-container">
      <main className="hr-main">
        <h1 className="hr-title">INTERNAL SYSTEM</h1>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <form className="hr-login-form" onSubmit={handleSubmit}>
          <label>Email</label>
          <input
            type="email"
            className="hr-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label>Password</label>
          <input
            type="password"
            className="hr-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" className="primary-btn">Log In</button>
        </form>
      </main>
    </div>
  );
};

export default HRLogin;
