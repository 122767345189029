import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { Button } from "@mui/material";
import parse from "html-react-parser"; // Import html-react-parser
import "./CareerJobDetail.css"; // Import your custom CSS for styling
import JSONLDHelmet from "../components/SEO/JSONLDHelmet";

const CareerJobDetail = () => {
  const { title } = useParams(); // This is the job title from the URL parameters
  const [job, setJob] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const API_BASE_URL = process.env.REACT_APP_API;

  // Helper function to slugify titles
  const slugifyTitle = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const fetchJobDetail = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}api/hr/jobs/`);
      const jobs = response.data;

      // Find the job by slugified title
      const matchedJob = jobs.find((job) => slugifyTitle(job.Title) === title);

      if (matchedJob) {
        setJob(matchedJob);
      } else {
        setError("Job not found");
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching job details:", error);
      setError("Failed to fetch job details. Please try again.");
      setLoading(false);
    }
  }, [title, API_BASE_URL]);

  useEffect(() => {
    fetchJobDetail();
  }, [fetchJobDetail]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      {job && (
        <JSONLDHelmet
          // canonicalUrl={}
          jobDetails={{
            title: job?.Title ?? "",
            description: job.Description,
            employmentType: job.EmploymentType ?? "",
            postedDate: job.PostedDate ?? "",
            closeDate: job.CloseDate ?? "",
          }}
        />
      )}
      <div className="career-job-detail-container">
        <div className="job-detail-content">
          <div className="go-back-container">
            <Link to="/careers" style={{ textDecoration: "none" }}>
              <Button className="primary-btn primary-btn-go-back apply_btn">
                Go Back
              </Button>
            </Link>
          </div>
          <div className="job-info">
            <div className="info-text">
              {parse(job.Description)}{" "}
              {/* Use parse to render the description as HTML */}
            </div>
          </div>
          <div className="apply-section">
            <div className="apply-btn-container">
              <Link
                to={`/apply/${job.JobID}/${encodeURIComponent(job.Title)}`}
                style={{ textDecoration: "none" }}
              >
                <Button className="primary-btn primary-btn-white apply_btn">
                  Apply Now
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerJobDetail;
