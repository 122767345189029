import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './JobList.css';

const JobList = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('All');
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API;


  const fetchJobs = useCallback(async () => {
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.get(`${API_BASE_URL}api/hr/jobs/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setJobs(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching jobs:', error.response ? error.response.data : error.message);
      setError('Failed to fetch jobs. Please try again.');
      setLoading(false);
    }
  }, [API_BASE_URL]);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  const handleDelete = async (jobId) => {
    try {
      const token = localStorage.getItem('accessToken');
      await axios.delete(`${API_BASE_URL}api/hr/jobs/${jobId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setJobs((prevJobs) => prevJobs.filter((job) => job.JobID !== jobId));
    } catch (error) {
      console.error('Error deleting job:', error.response ? error.response.data : error.message);
    }
  };

  const handleLogout = () => {
    navigate('/internal-hr');
  };

  const departments = ['All', ...new Set(jobs.map((job) => job.Department))];

  const filteredJobs = selectedDepartment === 'All' 
    ? jobs 
    : jobs.filter((job) => job.Department === selectedDepartment);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="job-list-page">
      <div className="job-list-container">
        <h1 className="job-list-title">Job Listings</h1>
        {error && <p className="error-message">{error}</p>}
        <div className="logout-button-container">
          <button onClick={handleLogout} className="logout-button">Logout</button>
        </div>
        <div className="job-list-content">
          <div className="filter-section">
            <h3>Filter by Department</h3>
            {departments.map((dept) => (
              <button 
                key={dept} 
                onClick={() => setSelectedDepartment(dept)}
                className={selectedDepartment === dept ? 'active' : ''}
              >
                {dept}
              </button>
            ))}
          </div>
          <div className="job-table-container">
            <table className="job-table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Department</th>
                  <th>Posted Date</th>
                  <th>Close Date</th>
                  <th>Location</th>
                  <th>Applications</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredJobs.map((job) => {
                  return (
                    <tr key={job.JobID} onClick={() => navigate(`/update-job/${job.JobID}`)}>
                      <td>{job.Title}</td>
                      <td>{job.Department}</td>
                      <td>{new Date(job.PostedDate).toLocaleDateString()}</td>
                      <td>{new Date(job.CloseDate).toLocaleDateString()}</td>
                      <td>{job.Location}</td>
                      <td>{job.application_count}</td>
                      <td className="action-buttons">
                        <button onClick={(e) => { e.stopPropagation(); handleDelete(job.JobID); }}>
                          Delete
                        </button>
                        <Link to={`/job-details/${job.JobID}`} onClick={(e) => e.stopPropagation()}>
                        View Details
                      </Link>
                      <Link to={`/job/${job.JobID}/applications`} onClick={(e) => e.stopPropagation()}>
                        View Applications
                      </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <Link to="/create-job" className="create-link">Create New Job</Link>
      </div>
    </div>
  );
};

export default JobList;