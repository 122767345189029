import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser'; // For parsing HTML content in the description
import './JobDetail.css'; // Import your custom CSS for styling

const JobDetail = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const API_BASE_URL = process.env.REACT_APP_API;
  useEffect(() => {
    fetchJobDetail();
  });

  const fetchJobDetail = async () => {
    try {
      const token = localStorage.getItem('accessToken'); // Ensure token key matches
      const response = await axios.get(`${API_BASE_URL}api/hr/jobs/${id}/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setJob(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching job details:', error);
      setError('Failed to fetch job details. Please try again.');
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="job-detail-container">
      <div className="job-detail-content">
        <div className="job-info">
          <div className="info-text">
            {job.Description && typeof job.Description === 'string'
              ? parse(job.Description)
              : 'No description available'}
          </div>
          <h2 className="info-heading">Department</h2>
          <p className="info-text">{job.Department}</p>
          <h2 className="info-heading">Location</h2>
          <p className="info-text">{job.Location}</p>
          <h2 className="info-heading">Close Date</h2>
          <p className="info-text">{job.CloseDate ? new Date(job.CloseDate).toLocaleDateString() : 'Not specified'}</p>
        </div>
      </div>
    </div>
  );
};

export default JobDetail;