import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home/Home.js";
import ProductGateway from './pages/product/ProductGateway';
import Blog from "./pages/Blog";
import Contact from "./pages/contact/Contact";
import AboutUs from "./pages/AboutUs";
// import Login from './pages/account/Login/Login';
// import Signup from './pages/account/Signup/Signup';

import SalesSupport from "./pages/contact/SalesSupport";
import Privacy from "./pages/Privacy";
import Pricing from "./pages/Pricing";
import Career from "./pages/Career";
import Resource from "./pages/Resource";
import UseCases from "./pages/usecases/UseCase";
import CustomerService from "./pages/CustomerService";
import LandingPage from "./pages/LandingPage/LandingPageGateway.js";
import Marketplace from "./pages/Marketplace";

// import Community from './pages/Community';
import Post from "./components/Blog/Post";
import Header from "./components/Header";
import HRLogin from "./components/internal-hr/HRLogin/HRLogin";

import ApplicationSuccessPage from "./pages/ApplicationSuccessPage";
import JobDetails from "./components/internal-hr/InternalJob/JobDetails";
import JobList from "./components/internal-hr/InternalJob/JobList";
import JobForm from "./components/internal-hr/InternalJob/JobForm";
import UpdateJobForm from "./components/internal-hr/InternalJob/UpdateJobForm";
import CareerJobDetail from "./pages/CareerJobDetail";
import ApplicationForm from "./pages/ApplicationForm";
// import ApplicationDetail from './components/internal-hr/InternalJob/ApplicationDetail';
import ApplicationList from "./components/internal-hr/InternalJob/ApplicationList";

import loadAnimations from "./animate.js";

import "./App.css";

import Billing from "./pages/billing/Billing";
import { HelmetProvider } from "react-helmet-async";
// import PurchaseForm from './pages/billing/PurchaseForm';

const PrivateRoute = ({ children }) => {
  const isLoggedIn = !!localStorage.getItem("accessToken");
  return isLoggedIn ? children : <Navigate to="/internal-hr" />;
};

function AppContent() {
  const pathName = useLocation().pathname;

  // scrolls to top of page when changing links
  useEffect(() => {
    // load home page animations
    if (pathName === "/" || pathName === "/contact") {
      loadAnimations();
    }
    window.scrollTo(0, 0);
  }, [pathName]);

  return (
    // <Router>
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<ProductGateway />} />
        <Route path="/use-cases" element={<UseCases />} />
        <Route path="/customer-service" element={<CustomerService />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact/sales-support" element={<SalesSupport />} />
        {/* <Route path='/purchase' element={<PurchaseForm/>}/> */}
        <Route path="/contact/*" element={<Contact />} />
        <Route path="/aboutus" element={<AboutUs />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/billing" element={<Billing />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/:title" element={<CareerJobDetail />} />
        <Route path="/apply/:jobId/:jobTitle" element={<ApplicationForm />} />
        <Route path="/resource" element={<Resource />} />
        <Route path="/turbo/lp" element={<LandingPage />} />
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/blog/:id" element={<Post />} />
        <Route path="/internal-hr" element={<HRLogin />} />
        {/* <Route path="/community" element={<Community />} /> */}
        <Route
          path="/application-success-page"
          element={<ApplicationSuccessPage />}
        />
        <Route
          path="/internal-hr-joblist"
          element={
            <PrivateRoute>
              <JobList />
            </PrivateRoute>
          }
        />
        <Route
          path="/create-job"
          element={
            <PrivateRoute>
              <JobForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/update-job/:jobId"
          element={
            <PrivateRoute>
              <UpdateJobForm />
            </PrivateRoute>
          }
        />
        <Route path="/job-details/:id" element={<JobDetails />} />
        {/* <Route path="/job/:job_id/applications/:application_id" element={<ApplicationDetail />} /> */}
        {/* <Route path="/job/:jobId/applications" element={<ApplicationList />} /> */}
        <Route
          path="/job/:jobId/applications"
          element={
            <PrivateRoute>
              <ApplicationList />
            </PrivateRoute>
          }
        />
      </Routes>
      {/* </Router> */}
    </>
  );
}

function App() {
  return (

    <HelmetProvider>
      <Router>
        <AppContent />
      </Router>
    </HelmetProvider>
  );
}

export default App;
